<script>
import IconColorMixin from '@/mixins/IconColorMixin';

export default defineNuxtComponent({
  mixins: [IconColorMixin],
  props: {
    iconName: {
      type: String,
      default: '',
    },
    size: {
      type: [Number, String],
      default: 24,
    },
    accent: {
      type: String,
      default: 'currentColor',
    },
    darkMode: {
      type: Boolean,
      default: false,
    },
  },
});
</script>

<template>
  <svg
    :width="size"
    :height="size"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M5 17H6.39887C7.81501 17 9.1256 16.2512 9.84474 15.0313L11.6316 12L13.4184 8.96875C14.1376 7.74879 15.4481 7 16.8643 7H19"
      :stroke="getBaseColor(darkMode)"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M17 9.53967V4.46033C17 4.05582 17.4553 3.81874 17.7867 4.05071L21.4148 6.59038C21.6992 6.78943 21.6992 7.21057 21.4148 7.40962L17.7867 9.94929C17.4553 10.1813 17 9.94418 17 9.53967Z"
      :fill="accent"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M5 6C4.44772 6 4 6.44772 4 7C4 7.55228 4.44772 8 5 8H6.39887C7.46098 8 8.44392 8.56159 8.98327 9.47656L9.3277 10.0609L10.4588 8.08149C9.52507 6.7822 8.0171 6 6.39887 6H5ZM11.6401 10.0453L10.4974 12.0451L10.7701 12.5078L12.557 15.5391C13.4559 17.064 15.0941 18 16.8643 18H19C19.5523 18 20 17.5523 20 17C20 16.4477 19.5523 16 19 16H16.8643C15.8022 16 14.8192 15.4384 14.2799 14.5234L12.493 11.4922L11.6401 10.0453Z"
      :fill="getBaseColor(darkMode)"
    />
    <path
      d="M17 19.5397V14.4603C17 14.0558 17.4553 13.8187 17.7867 14.0507L21.4148 16.5904C21.6992 16.7894 21.6992 17.2106 21.4148 17.4096L17.7867 19.9493C17.4553 20.1813 17 19.9442 17 19.5397Z"
      :fill="accent"
    />
  </svg>
</template>
